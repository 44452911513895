import { ReactNode, useMemo } from 'react';
import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useSettings } from 'src/contexts/SettingsContext';
import { overrideComponents } from '../../theme/overrides';

export function ThemeColorPresets({ children }: { children: ReactNode }) {
  const defaultTheme = useTheme();

  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor,
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
    }),
    [setColor, defaultTheme],
  );

  const theme = createTheme(themeOptions);

  theme.components = overrideComponents(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
