import 'src/locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MotionLazyContainer } from 'src/components/animate/MotionLazyContainer';
import { ChartStyle } from 'src/components/chart';
import { ConfirmProvider } from 'src/components/confirmation/ConfirmContext';
import { NotistackProvider } from 'src/components/NotistackProvider';
import { ProgressBar } from 'src/components/ProgressBar';
import { ThemeSettings } from 'src/components/settings';
import { defaultSettings } from 'src/config';
import { AuthProvider } from 'src/contexts/AuthProvider';
import { CollapseDrawerProvider } from 'src/contexts/CollapseDrawerContext';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import { ThemeProvider } from 'src/theme';
import { apolloClient } from 'src/utils/apollo-client';
import { createEmotionCache } from 'src/utils/createEmotionCache';

const clientSideEmotionCache = createEmotionCache();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>

        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <CollapseDrawerProvider>
              <SettingsProvider defaultSettings={defaultSettings}>
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <NotistackProvider>
                        <ConfirmProvider>
                          <ChartStyle />
                          <ProgressBar />
                          {getLayout(<Component {...pageProps} />)}
                        </ConfirmProvider>
                      </NotistackProvider>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </SettingsProvider>
            </CollapseDrawerProvider>
          </AuthProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
}
