import { ReactNode } from 'react';
import { IconifyIcon } from '@iconify/react';
import { closeSnackbar, SnackbarKey, SnackbarProvider } from 'notistack';
import { Box, Collapse, GlobalStyles } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useSettings } from 'src/contexts/SettingsContext';
import { ColorSchema } from 'src/theme/palette';
import { IconButtonAnimate } from './animate/IconButtonAnimate';
import Iconify from './Iconify';

interface NotistackProviderProps {
  children: ReactNode;
}

export function NotistackProvider({ children }: NotistackProviderProps) {
  const { themeDirection } = useSettings();

  const isRTL = themeDirection === 'rtl';

  const onClose = (key: SnackbarKey) => () => {
    closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon="eva:info-fill" color="info" />,
          success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />,
          warning: <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />,
          error: <SnackbarIcon icon="eva:alert-circle-fill" color="error" />,
        }}
        // With close as default
        action={(key) => (
          <IconButtonAnimate size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <Iconify icon="eva:close-fill" />
          </IconButtonAnimate>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#__next': {
          '& .notistack-SnackbarContainer': {
            top: '12px',
            right: '12px',
            '.notistack-MuiContent': {
              padding: '0 12px 0 8px',
              borderRadius: theme.shape.borderRadius,
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
              fontWeight: theme.typography.fontWeightMedium,
            },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '.notistack-CollapseWrapper': {
            padding: 0,
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

interface SnackbarIconProps {
  icon: IconifyIcon | string;
  color: ColorSchema;
}

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
}
