/* eslint-disable import/no-cycle */

import { ApolloCache, ApolloClient, from, InMemoryCache, makeVar, useReactiveVar } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { GraphQLError } from 'graphql';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { AUTH } from 'src/contexts/Firebase';

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

const networkStatusNotifier = createNetworkStatusNotifier();

export const { useApolloNetworkStatus } = networkStatusNotifier;

type RetryFunction = (retry: boolean) => void;
const authErrorsVar = makeVar<RetryFunction[]>([]);
export function useAuthErrors(): [RetryFunction[], () => void] {
  const state = useReactiveVar(authErrorsVar);
  const resetState = () => authErrorsVar([]);
  return [state, resetState];
}

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await AUTH.currentUser?.getIdToken();
  const loginToken = localStorage.getItem('loginToken');
  const accountId = localStorage.getItem('accountId');
  return {
    headers: {
      ...headers,
      'x-login-token': loginToken,
      ...(token && { authorization: `Bearer ${token}` }),
      ...(accountId && { 'x-account-id': accountId }),
    },
  };
});

export const notFoundErrorVar = makeVar<GraphQLError | null>(null);
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      if (error.message.includes('not found')) {
        notFoundErrorVar(error);
      }
      console.log(
        `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const retryLink = new RetryLink({
  attempts: {
    retryIf: (error, operation) => {
      // if requests fail because we don't have a valid session
      if (error.statusCode === 409 && operation.operationName !== 'ReleaseToken') {
        // we return a promise that will wait till the user stole a session token and then retry all requests
        return new Promise((resolve) => {
          authErrorsVar([...authErrorsVar(), resolve]);
        });
      }
      // but we don't retry for other errors as we cannot solve their causative problem
      return false;
    },
  },
  delay: {
    initial: 0,
    max: 1,
  },
});

export const invalidateCacheOnMutate = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'allInventory' });
  cache.evict({ fieldName: 'inventory' });
  cache.evict({ fieldName: 'partners' });
  cache.evict({ fieldName: 'partner' });
  cache.evict({ fieldName: 'contracts' });
  cache.evict({ fieldName: 'contract' });
  cache.evict({ fieldName: 'bonuses' });
  cache.evict({ fieldName: 'bonus' });
  cache.evict({ fieldName: 'dashboardDiscount' });
  cache.evict({ fieldName: 'dashboardRevenueDetails' });
  cache.evict({ fieldName: 'dashboardForecast' });
  cache.evict({ fieldName: 'dashboardOffers' });
  cache.evict({ fieldName: 'dashboardInventory' });
  cache.evict({ fieldName: 'dashboardPartner' });
  cache.evict({ fieldName: 'inventoryDetails' });
  cache.evict({ fieldName: 'invoiceExports' });
};

export const apolloClient = new ApolloClient({
  link: from([retryLink, authLink, errorLink, networkStatusNotifier.link, httpLink]),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'cache-only', // don't refetch immediately on cache evict
    },
    mutate: {
      awaitRefetchQueries: true,
      update: invalidateCacheOnMutate,
    },
  },
  cache: new InMemoryCache({
    dataIdFromObject: () => false,
  }),
});
