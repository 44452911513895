import { ReactNode } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';

export interface ConfirmOptions {
  title?: ReactNode;
  titleProps?: DialogTitleProps;
  content?: ReactNode | null;
  contentProps?: DialogContentProps;
  confirmationText?: ReactNode;
  cancellationText?: ReactNode;
  dialogProps?: Omit<DialogProps, 'open'>;
  dialogActionsProps?: DialogActionsProps;
  confirmationButtonProps?: ButtonProps;
  cancellationButtonProps?: ButtonProps;
  allowClose?: boolean;
  hideCancelButton?: boolean;
}

interface ConfirmationDialogProps {
  open: DialogProps['open'];
  options: ConfirmOptions;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

export function ConfirmationDialog({ open, options, onCancel, onConfirm, onClose }: ConfirmationDialogProps) {
  const {
    title,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    hideCancelButton,
  } = options;

  return (
    <Dialog {...dialogProps} open={open} onClose={allowClose ? onClose : undefined}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content && <DialogContent {...contentProps}>{content}</DialogContent>}
      <DialogActions {...dialogActionsProps}>
        {!hideCancelButton && (
          <Button color="error" {...cancellationButtonProps} onClick={onCancel}>
            {cancellationText}
          </Button>
        )}
        <Button color="primary" {...confirmationButtonProps} onClick={onConfirm}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
