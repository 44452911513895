import { ReactNode } from 'react';
import { domMax, LazyMotion } from 'framer-motion';

export function MotionLazyContainer({ children }: { children: ReactNode }) {
  return (
    <LazyMotion strict features={domMax}>
      {children}
    </LazyMotion>
  );
}
