import { SettingsDrawer } from './drawer/SettingsDrawer';
import { ThemeColorPresets } from './ThemeColorPresets';
import { ThemeContrast } from './ThemeContrast';
import ThemeLocalization from './ThemeLocalization';
import ThemeRtlLayout from './ThemeRtlLayout';

const isDevelopment = process.env.NODE_ENV === 'development';

interface Props {
  children: React.ReactNode;
}

export function ThemeSettings({ children }: Props) {
  return (
    <ThemeColorPresets>
      <ThemeContrast>
        <ThemeLocalization>
          <ThemeRtlLayout>
            {children}
            {isDevelopment && <SettingsDrawer />}
          </ThemeRtlLayout>
        </ThemeLocalization>
      </ThemeContrast>
    </ThemeColorPresets>
  );
}
