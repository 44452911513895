import { InvoiceDocType } from 'src/api';
import { getQueryString } from 'src/utils/getQueryString';

export const GITHUB_REPO = 'https://github.com/sponsorights/sponsorights-ui';

export const PATH_AUTH = {
  root: '/auth',
  login: '/auth/login',
  register: '/auth/register',
  resetPassword: '/auth/reset-password',
  newPassword: '/auth/new-password',
};

export enum InventoryTabs {
  Overview = 'overview',
  Offers = 'offers',
  Contracts = 'contracts',
  Tasks = 'tasks',
  Files = 'files',
}

export enum ContractSummaryTabs {
  Contracts = 'contracts',
  Bonuses = 'bonuses',
  Invoices = 'invoices',
  Notes = 'notes',
  Tasks = 'tasks',
}

export const PATH_APP = {
  root: '/',

  users: {
    root: '/users',
    new: '/users/new',
    view: (id: string) => `/users/${id}`,
    edit: (id: string) => `/users/${id}/edit`,
  },

  accounts: {
    root: '/accounts',
    new: '/accounts/new',
    view: (id: string) => `/accounts/${id}`,
    edit: (id: string) => `/accounts/${id}/edit`,
  },

  inventory: {
    root: '/inventory/rights',
    new: '/inventory/rights/new',
    view: (id: string) => `/inventory/rights/${id}`,
    edit: (id: string) => `/inventory/rights/${id}/edit`,
    files: (id: string) => `inventory/${id}`,

    softRoot: [],
    softView: (id: string) => [id],
    softEdit: (id: string) => [id, 'edit'],
  },

  bundles: {
    root: '/inventory/bundles',
    new: '/inventory/bundles/new',
    edit: (id: string) => `/inventory/bundles/${id}/edit`,
  },

  bonuses: {
    root: '/inventory/bonuses',
    new: '/inventory/bonuses/new',
    edit: (id: string, season?: string) => `/inventory/bonuses/${id}/edit${getQueryString({ season })}`,
  },

  settings: {
    root: '/settings',
    tab: (id: string) => `/settings/${id}`,
  },

  invoices: {
    root: '/invoices',
  },

  partners: {
    root: '/partners',
    new: '/partners/new',
    view: (id: string) => `/partners/${id}`,
    edit: (id: string) => `/partners/${id}/edit`,
  },

  offers: {
    root: '/offers',
    view: (id: string, season?: string, tab?: ContractSummaryTabs) =>
      `/offers/${id}${getQueryString({ season, tab })}`,
  },

  contracts: {
    root: '/contracts',
    files: (id: string) => `contracts/${id}`,
  },

  contract: {
    root: '/contracts',
    new: '/contracts/new',
    view: (id: string, season?: string, tab?: ContractSummaryTabs) =>
      `/contracts/${id}${getQueryString({ season, tab })}`,
    edit: (id: string) => `/contracts/${id}/edit`,
    template: (id: string) => `/contracts/${id}/template`,
    documents: (id: string) => `/contracts/${id}/documents`,
    invoices: (contractId: string, invoiceId: string) => `/contracts/${contractId}/invoices/${invoiceId}`,

    softRoot: [],
    softNew: ['new'],
    softView: (id: string) => [id],
    softEdit: (id: string) => [id, 'edit'],
    softTemplate: (id: string) => [id, 'template'],
    softDocuments: (id: string) => [id, 'documents'],
    softInvoices: (contractId: string, invoiceId: string) => [contractId, 'invoices', invoiceId],
  },

  tasks: {
    root: '/tasks/contracts',
    contracts: '/tasks/contracts',
    matches: '/tasks/matches',
    inventory: '/tasks/inventory',
  },

  files: (accountId: string, path = '') => `/accounts/${accountId}/${path}`,
  downloadInvoice: (
    accountId: string,
    contractId: string,
    invoiceId: string,
    type: InvoiceDocType,
    filename: string,
  ) =>
    `/accounts/${accountId}/contracts/${contractId}/invoices/${invoiceId}/${type.toLowerCase()}/${filename}`,

  templates: {
    contract: {
      path: 'templates/contract-templates',
    },
    invoice: {
      path: 'templates/invoice-templates',
      localPath: '/assets/invoice-template.docx',
    },
    cancellationInvoice: {
      path: 'templates/cancellation-invoice-templates',
      localPath: '/assets/cancellation-invoice-template.docx',
    },
  },
};

export function resolvePath(...segments: (string | string[])[]) {
  return [segments].flat(Infinity).filter(Boolean).join('/');
}
