export function getQueryString(params: Record<string, string | undefined>): string {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];
    if (value != null && value !== '') {
      searchParams.append(key, value);
    }
  }

  const queryString = searchParams.toString();

  return queryString ? `?${queryString}` : '';
}
